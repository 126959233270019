<template>
  <div>
    <b-form-group
      id="sigle-legam-group"
      label="Sigle LEGaM:"
      label-for="sigle-legam"
    >
      <b-form-input
        id="sigle-legam"
        v-model="model.sigleLegam"
        placeholder="Sigle LEGaM"
      ></b-form-input>
    </b-form-group>

    <!-- <b-form-group
      id="sigle-other-group"
      label="Sigle other:"
      label-for="sigle-other"
    >
      <b-form-input
        id="sigle-other"
        v-model="model.sigleOther"
        placeholder="Sigle other"
      ></b-form-input>
    </b-form-group> -->

    <!-- <b-form-group
      id="localization-group"
      label="Localization"
      label-for="localization"
    >
      <! -- <b-form-input
        id="genre"
        v-model="model.genreId"
        placeholder="Genre"
      ></b-form-input> - ->
      <LocalizationSelect @select="selectLocalization" />
    </b-form-group> -->

    <b-form-group
      id="genre-group"
      label="Genre:"
      label-for="genre"
    >
      <!-- <b-form-input
        id="genre"
        v-model="model.genreId"
        placeholder="Genre"
      ></b-form-input> -->
      <GenreSelect @select="selectGenre" />
    </b-form-group>

    <b-form-group
      id="discourse-modality-group"
      label="Discourse modality:"
      label-for="discourse-modality"
    >
      <b-form-input
        id="discourse-modality"
        v-model="model.discourseModality"
        placeholder="Discourse modality"
      ></b-form-input>
    </b-form-group>

    <b-row>
      <b-col>
        <b-form-group
          id="date-group"
          label="Date:"
          label-for="dateSpec"
        >
          <b-form-input
            id="date-spec"
            v-model="model.dateSpec"
            placeholder="Date Spec"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="date-num-group"
          label="Date (num):"
          label-for="date-num"
        >
          <b-form-input
            id="date-num"
            v-model="model.dateNum"
            type="date"
            placeholder="Date (num)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group
      id="reference-group"
      label="Reference"
      label-for="reference"
    >
      <b-form-textarea
        id="reference"
        placeholder="Reference"
        v-model="model.reference"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      id="comment-scripta-group"
      label="Comment scripta:"
      label-for="comment-scripta"
    >
      <b-form-textarea
        id="comment-scripta"
        placeholder="Comment scripta"
        v-model="model.commentScripta"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      id="comment-general-group"
      label="Comment general:"
      label-for="comment-general"
    >
      <b-form-textarea
        id="comment-general"
        placeholder="Comment general"
        v-model="model.commentGeneral"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      id="title-group"
      label="Title:"
      label-for="title"
    >
      <TitleSelect @select="selectTitle" />
    </b-form-group>
  </div>
</template>

<style scoped>
</style>

<script>
  import GenreSelect from '@/components/Bibliography/GenreSelect.vue'
  // import LocalizationSelect from '@/components/Bibliography/LocalizationSelect.vue'
  import TitleSelect from '@/components/Bibliography/TitleSelect.vue'
  import HelpStore from '@/store/helpstore.js'

  export default {
    name: 'TextNewEdit',
    props: ['text'],
    data(){
      return {
        // TODO: fetch from API
        model: (this.text ? this.text : {
          sigleLegam: null,
          genreId: null,
          localizationId: null,
          discourseModality: null,
          reference: '',
          dateSpec: null,
          dateNum: null,
          commentScripta: '',
          commentGeneral: '',
          titles: []
        })
      }
    },
    components: {
      GenreSelect,
      // LocalizationSelect,
      TitleSelect
    },
    mounted(){
      HelpStore.item = this.model
    },
    methods: {
      selectGenre(genre){
        this.model.genre = genre
        this.model.genreId = genre.id
      },
      // selectLocalization(localization){
      //   this.model.localization = localization
      //   this.model.localizationId = localization.id
      // },
      selectTitle(titles){
        this.model.titles = titles
      }
    }
  }
</script>
