import { render, staticRenderFns } from "./TextNewEdit.vue?vue&type=template&id=fa8fc18c&scoped=true"
import script from "./TextNewEdit.vue?vue&type=script&lang=js"
export * from "./TextNewEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa8fc18c",
  null
  
)

export default component.exports