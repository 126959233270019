<template>
  <div style="position: relative">
    <button class="btn-legam-xs btn-legam-dt-filter table-filter" :class="filteredClass" @click="show = !show">Filter</button>
    <div class="filter-box p-3" v-if="show">
      <b-form-group
        id="genres-1"
        label="Genres:"
        label-for="genres"
      >
        <multiselect
          v-model="genresSelected"
          :options="genreOptions"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Pick some"
          label="text"
          track-by="text"
          :preselect-first="false">
        </multiselect>
      </b-form-group>
      <button class="btn-legam-xs btn-legam-dt-filter" @click="show = false">Close</button>
      <button class="btn-legam-xs btn-legam-dt-filter" @click="clearFilter">Clear</button>
    </div>
  </div>
</template>

<style scoped>
  .filtered {
    background-color: #6c757d;
    border: 1px solid #ced4da;
  }

  .not-filtered {
    color: #6c757d;
    background-color: #fff;
    border: 1px solid #ced4da;
  }

  .filter-box {
    position: absolute;
    background-color: #e8e8e8;
    padding: 10px;
    border-radius: 3px;
    z-index: 10;
    top: 10px;
    left: 20px;
    width: 350px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import Multiselect from 'vue-multiselect'
  import config from '@/config.js'

  export default {
    name: 'filterGenreComponentDatatables',
    props: ['value'],
    data(){
      return {
        show: false,
        genresSelected: [],
        config: config
      }
    },
    components: {
      Multiselect
    },
    methods: {
      clearFilter(){
        this.show = false
        this.genresSelected = []
      },
      selectGenre(){}
    },
    computed: {
      ...mapState({
        genres: state => state.common.genresAll
      }),
      genreOptions(){
        return this.genres.map(genre => {
          return {
            value: genre.id,
            text: genre.display
          }
        }).sort((a, b) => (a.text > b.text) ? 1 : -1)
      },
      filteredClass(){
        return (this.genresSelected.length > 0 ? 'filtered' : 'not-filtered')
      }
    },
    mounted () {
      this.$store.dispatch('loadGenres')
    },
    watch: {
      "genresSelected"(){
        let selectedGenres = this.genresSelected.map(genre => genre.value)
        if (!config.editable) {
          selectedGenres = this.genresSelected.map(genre => genre.text)
        }
        this.$emit('update', selectedGenres)
      }
    }
  }
</script>
