<template>
  <div>
    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col cols="4" class="p-0">
          <PageTitle v-bind:title="'Texts'" />
        </b-col>
        <b-col cols="8" class="text-right" v-if="!config.editable">
          <BibLabelSelectPI class="mb-3" />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <div v-if="$store.state.user.settings.isBiblabelFiltered && config.editable" class="mb-3 text-orange">
            <b-icon icon="exclamation-circle-fill" aria-hidden="true"></b-icon>
            Entries in this table are filtered by personal preference for bibliography set in user settings
            ({{ $store.getters.getBibLabelsByIds($store.state.user.settings.biblabels).map(biblabel => biblabel.label).join(', ') }})
          </div>
          <b-button v-if="config.editable" variant="primary" :disabled="!$store.getters.checkRole('legam.bib.text.add')" size="sm" class="float-right mb-4" v-b-modal.modal-new-text>
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            Add new text
          </b-button>
          <b-modal v-if="config.editable" id="modal-new-text" title="New text" ok-title="Save" @ok="saveText" size="lg">
            <TextNewEdit />
          </b-modal>
          <LegamDataTables
            v-if="config.editable"
            v-bind="$data"
            :additionalData="tableAdditionalData"
            @selectChanged="selectChanged"
          />
          <LegamDataTablesClientSide
            v-else
            v-bind="$data"
            :additionalData="tableAdditionalData"
            @click="openDetails"
            :additionalDataFilter="tableAdditionalDataFilter"
            ref="PITable"
          />
        </b-col>
      </b-row>
    </b-container>

    <div class="merge-float" v-if="selected.length && config.editable">
      <b-badge href="#" variant="info" class="mr-1" v-for="text in selected" :key="text.id" @click="removeSelected(text)">
        <span v-html="text.sigleLegam"></span>
        <b-icon icon="trash" aria-hidden="true" class="ml-1"></b-icon>
      </b-badge>
      <span class="btn-legam-xxs btn-legam-xs btn-legam-blue" v-if="selected.length > 1" v-b-modal.modal-merge>
        <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
        Merge texts
      </span>
      <span class="btn-legam-xxs btn-legam-xs" @click="selected = []">
        <b-icon icon="x" aria-hidden="true"></b-icon>
      </span>
      <b-modal id="modal-merge" title="Merge texts" ok-title="Merge" @ok="merge" size="md">
        <b-badge href="#" variant="info" class="mr-1" v-for="text in selected" :key="'modal' + text.id" @click="removeSelected(text)">
          <span v-html="text.sigleLegam"></span>
          <b-icon icon="trash" aria-hidden="true" class="ml-1"></b-icon>
        </b-badge>
        <b-form-group
          id="main-entity-grp"
          label="Merge to"
          label-for="main-entity"
          class="mt-3"
          description="This text will keep all it's data (other texts will move their data to this text and then disappear)"
        >
          <b-form-select
            v-model="mainEntityId"
            :options="selected.map(text => {
              return {
                'text': text.sigleLegam,
                'value': text.id
              }
            })"
          ></b-form-select>
        </b-form-group>
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  * >>> .tdAction {
    width: 150px;
  }
  * >>> .tdManuscriptDates {
    width: 130px;
  }

  * >>> .btn-legam-dt-filter:hover ~ * >>> .filter-box {
    display: block;
  }
  .merge-float {
    position: fixed;
    bottom: 15px;
    left: 20px;
    background-color: #bebebe9a;
    padding: 3px 8px 5px;
    border-radius: 3px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'
  import BibLabelSelectPI from '@/components/Bibliography/BibLabelSelectPI.vue'
  import tdColAction from "@/components/tdColAction"
  import FilterScriptaLanguageComponentDatatables from "@/components/FilterScriptaLanguageComponentDatatables"
  import FilterGenreComponentDatatables from "@/components/FilterGenreComponentDatatables"
  import tdColTooltip from "@/components/tdColTooltip"
  import TextNewEdit from "@/components/Bibliography/TextNewEdit"
  import HelpStore from '@/store/helpstore.js'
  import config from '@/config.js'

  export default {
    name: 'TextList',
    data(){
      return {
        columns: [
          { title: 'Sigle', field: 'sigleLegam', sortable: true, searchable: true },
          { title: 'Title', field: 'title.titleName', sortable: true, searchable: true },
          { title: 'Genre', field: 'genre.display', sortable: true, searchable: true, sortComp: FilterGenreComponentDatatables },
          { title: 'Date', field: 'dateSpec', sortable: true, searchable: true },
          { title: 'Scriptae', field: 'localization.scripta.code', sortable: true, searchable: true, tdComp: tdColTooltip,
            transformData: function(row){
              return {
                shortText: (row.localization && row.localization.scriptas) ? row.localization.scriptas.map(scripta => scripta.code).join(', ') : '',
                longText: (row.localization && row.localization.scriptas) ? row.localization.scriptas.map(scripta => `${scripta.code}${scripta.language ? ' (' + scripta.language.name + ')' : ''}`).join(', ') : '',
                id: row.sigleLegam.replace(/ /gi, '')
              }
            },
            sortComp: FilterScriptaLanguageComponentDatatables
          },
          { title: 'Man. Date', field: 'manuscriptRange', thClass: 'tdManuscriptDates', sortable: false, searchable: false,
            transformData: function(row){
              let retVal = ''
              let minVal = (row.manuscriptRange.min && row.manuscriptRange.min.dateNum && row.manuscriptRange.min.dateNum) ? row.manuscriptRange.min.dateNum : ''
              let maxVal = (row.manuscriptRange.max && row.manuscriptRange.max.dateNum && row.manuscriptRange.max.dateNum) ? row.manuscriptRange.max.dateNum : ''
              if (minVal && maxVal && minVal != maxVal){
                retVal = `${minVal} - ${maxVal}`
              }
              else if (minVal) {
                retVal = minVal
              }
              else if (maxVal) {
                retVal = maxVal
              }
              return retVal
            }
          },
          { title: 'Label', field: 'biblabels', sortable: true, searchable: true },
          { title: 'Action', field: 'id', tdComp: tdColAction, thClass: 'tdAction',
            transformData: function(row){
              return [
                { text: null, icon: 'list-ul', id: row.sigleLegam, to: {
                  name: 'TextDetails',
                  params: {
                    slug: row.sigleLegamNormalized || "-",
                    id: row.id
                  }}
                }
              ]
            }
          }
        ],
        columnsPI: [
          { title: 'Sigle', field: 'sigleLegam', sortable: true, searchable: true },
          { title: 'Title', field: 'title', sortable: true, searchable: true },
          { title: 'Genre', field: 'genre', sortable: true, searchable: true, sortComp: FilterGenreComponentDatatables },
          { title: 'Date', field: 'dateSpec', sortable: true, searchable: true },
          { title: 'Scriptae', field: 'localization', sortable: true, searchable: true,
            // transformData: function(row){
            //   return {
            //     shortText: (row['localization.scripta.code'] && row['localization.scripta.code'].scriptas) ? row['localization.scripta.code'].scriptas.map(scripta => scripta.code).join(', ') : '',
            //     longText: (row['localization.scripta.code'] && row['localization.scripta.code'].scriptas) ? row['localization.scripta.code'].scriptas.map(scripta => `${scripta.code}${scripta.language ? ' (' + scripta.language.name + ')' : ''}`).join(', ') : '',
            //     id: row.sigleLegam.replace(/ /gi, '')
            //   }
            // },
            sortComp: FilterScriptaLanguageComponentDatatables
          },
          // { title: 'Man. Date', field: 'manuscriptRange', thClass: 'tdManuscriptDates', sortable: false, searchable: false,
          //   transformData: function(row){
          //     let retVal = ''
          //     let minVal = (row.manuscriptRange.min && row.manuscriptRange.min.dateNum && row.manuscriptRange.min.dateNum) ? row.manuscriptRange.min.dateNum : ''
          //     let maxVal = (row.manuscriptRange.max && row.manuscriptRange.max.dateNum && row.manuscriptRange.max.dateNum) ? row.manuscriptRange.max.dateNum : ''
          //     if (minVal && maxVal && minVal != maxVal){
          //       retVal = `${minVal} - ${maxVal}`
          //     }
          //     else if (minVal) {
          //       retVal = minVal
          //     }
          //     else if (maxVal) {
          //       retVal = maxVal
          //     }
          //     return retVal
          //   }
          // },
          { title: 'Label', field: 'biblabels', sortable: true, searchable: false }
        ],
        url: '/texts/datatables',
        key: 1,
        searchableByColumn: true,
        selected: [],
        mainEntityId: null,
        config: config,
      }
    },
    computed: {
      ...mapState({
        userSettings: state => state.user.settings,
        userSettingsPI: state => state.user.settingsPI,
      }),
      tableAdditionalData(){
        return {
          'biblabel': this.userSettings.biblabels,
          'biblabelSuppressed': this.userSettings.biblabelsSuppressed,
        }
      }
    },
    components: {
      PageTitle,
      LegamDataTables,
      LegamDataTablesClientSide,
      TextNewEdit,
      BibLabelSelectPI,
    },
    methods: {
      tableAdditionalDataFilter(data) {
        if (this.userSettingsPI.biblabels.length) {
          data = data.filter(row => this.userSettingsPI.biblabels.includes(row.biblabels))
        }
        return data
      },
      saveText(){
        this.$store.dispatch('createText', HelpStore.item).then((response) => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Text created`
          })
          router.push(`/text/${response.data.sigleLegamNormalized}/${response.data.id}`)
        })
      },
      selectChanged(items){
        this.selected = items
        if (items.length){
          this.mainEntityId = items[0].id
        }
      },
      removeSelected(item) {
        this.selected = this.selected.filter(_item => item.id != _item.id)
      },
      openDetails(data) {
        router.push(`/text/-/${data.id}`)
      },
      merge() {
        this.$store.dispatch('mergeTexts', {
          mainTextId: this.mainEntityId,
          textIds: this.selected.filter(text => text.id != this.mainEntityId).map(text => text.id)
        }).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Texts merged`
          })
          this.selected = []
          this.mainEntityId = null
          this.key++
        })
      },
    },
    watch: {
      "userSettings"(){
        this.key++
      },
      userSettingsPI: {
        deep: true,
        handler() {
          if (this.$refs.PITable.table) {
            this.$refs.PITable.sortPageFilter()
          }
        }
      },
    }
  }
</script>
