<template>
  <div class="genres" v-if="genres.length">
    <Select2 :options="genresOption" @select="selectGenre($event)" :settings="{}" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import Select2 from 'v-select2-component'

  export default {
    name: 'GenreSelect',
    props: ['exclude'],
    components: {
      Select2
    },
    mounted() {
      this.$store.dispatch('loadGenres')
    },
    computed: {
      ...mapState({
        genres: state => state.common.genresAll
      }),
      genresOption(){
        return this.genres.map(genre => {
          return {
            id: genre.id,
            text: `${genre.display}`
          }
        }).sort((a, b) => (a.text > b.text) ? 1 : -1)
      }
    },
    methods: {
      selectGenre(event){
        let genre = null
        for (let _genre of this.genres){
          if (_genre.id == event.id){
            genre = _genre
            break
          }
        }
        this.$emit('select', genre)
      }
    }
  }
</script>
